.container {
  width: 9rem;
  height: 9rem;
  background-color: beige;
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
}
.container .tileButton {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  background-color: inherit;
}

.BLUE {
  background-color: rgba(0, 116, 179, 0.2);
}

.RED {
  background-color: rgba(185, 24, 24, 0.2);
}/*# sourceMappingURL=Tile.module.css.map */