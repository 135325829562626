.container {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #0074b3;
  color: white;
  gap: 2rem;
}
.container .title {
  color: inherit;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}
.container ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.container ul li {
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
}
.container li:active {
  background-color: rgba(0, 116, 179, 0.2);
}
.container li:hover {
  background-color: rgb(0, 71, 110);
}/*# sourceMappingURL=Navbar.module.css.map */