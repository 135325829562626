@import '../utilities/variables';
.container {
  border: 1px solid black;
  border-radius: 5px;

  display: inline-flex;
  flex-direction: column;

  width: $cardWidth;
  height: $cardHeight;

  //background-image: url('../images/Alpha.png');
  //https://source.unsplash.com/collection/483251
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: gray;
  user-select: none;

  &.BLUE {
    background-color: $cardBlue;
  }
  &.RED {
    background-color: $cardRed;
  }
}

.rank {
  text-align: left;
  color: white;
  font-size: 1rem;
  padding: 0 0.5rem;
  text-shadow: 0 0 3px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

.values {
  color: white;
  padding: 1em;
  font-size: 1.5em;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: auto;
  text-shadow: 0 0 5px black, 0 0 4px black, 0 0 4px black, 0 0 4px black;
  position: relative;
  bottom: -2.5rem;
  .N {
    grid-row: 1;
    grid-column: 1/-1;
    margin: -0.4rem 0;
  }
  .W,
  .E {
    grid-row: 2;
    margin: 0 0.2rem;
  }

  .S {
    grid-row: 3;
    grid-column: 1/-1;
    margin: -0.4rem 0;
  }
}

@keyframes flip {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: none;
  }
}
.flipped {
  animation: flip 0.7s;
}
