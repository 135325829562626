@import '../utilities/variables';
.cardContainer {
  margin: 1rem 0;
  display: flex;
  position: relative;
  .cardButton {
    position: relative;
    border: 0;
    padding: 0;
    z-index: 3;
  }

  .cardButton.selectAvailable:hover {
    bottom: 1rem;
  }

  .cardButton.isSelected {
    bottom: 1rem;
  }

  .cardHandSlot {
    position: relative;
    width: $cardWidth;
    height: $cardHeight;
    margin: 0 5px;

    .handEmptySlot {
      position: absolute;
      background-color: rgba(119, 136, 153, 0.4);
      margin: 0;
      padding: 0;
      top: 0;
      border-radius: 5px;
      width: $cardWidth;
      height: $cardHeight;
      z-index: 1;
    }
  }
}
