@import '../utilities/variables';
.container {
  width: 9rem;
  height: 9rem;
  background-color: beige;

  vertical-align: middle;
  margin: 0 auto;
  text-align: center;

  .tileButton {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    background-color: inherit;
  }
}

.BLUE {
  background-color: rgba($cardBlue, 0.2);
}

.RED {
  background-color: rgba($cardRed, 0.2);
}
