.container {
  border: 1px solid black;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  width: 6.5rem;
  height: 8.5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: gray;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.container.BLUE {
  background-color: rgb(0, 116, 179);
}
.container.RED {
  background-color: rgb(185, 24, 24);
}

.rank {
  text-align: left;
  color: white;
  font-size: 1rem;
  padding: 0 0.5rem;
  text-shadow: 0 0 3px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

.values {
  color: white;
  padding: 1em;
  font-size: 1.5em;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: auto;
  text-shadow: 0 0 5px black, 0 0 4px black, 0 0 4px black, 0 0 4px black;
  position: relative;
  bottom: -2.5rem;
}
.values .N {
  grid-row: 1;
  grid-column: 1/-1;
  margin: -0.4rem 0;
}
.values .W,
.values .E {
  grid-row: 2;
  margin: 0 0.2rem;
}
.values .S {
  grid-row: 3;
  grid-column: 1/-1;
  margin: -0.4rem 0;
}

@-webkit-keyframes flip {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: none;
  }
}

@keyframes flip {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: none;
  }
}
.flipped {
  -webkit-animation: flip 0.7s;
          animation: flip 0.7s;
}/*# sourceMappingURL=Card.module.css.map */